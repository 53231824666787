<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>

      <el-col :span="24">
        <div style="float: right">
          <el-button type="primary" size="large" @click="inviteUsers"
            >Invite Users</el-button
          >
        </div>
      </el-col>
      <el-col :span="4"><div /></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 20px 0"></div>
      </el-col>
    </el-row>
    <el-row>
     
      <el-col :span="24">
        <el-table
          :data="filterTableData"
          border
          style="width: 100%"
          size="large"
          v-loading="loading"
        >
          <el-table-column label="Name" prop="user_name" />
          <el-table-column label="Phone" prop="user_phone" />
          <el-table-column label="Email" prop="user_email" />
          <el-table-column label="Type" prop="user_type" />
          <el-table-column>
            <template #header>
              <el-input
                v-model="search"
                size="large"
                placeholder="Type to search"
                class="table-search-bar"
              />
            </template>
            <template #default="scope">
              <el-button
                link
                type="primary"
                size="large"
                @click="handleEdit(scope.$index, scope.row)"
                >Edit</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="4"><div /></el-col>
    </el-row>
  </el-main>
</template>

<script lang="ts" setup>
import { computed, ref, reactive } from "vue";
import router from "../../router";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";

const loading = ref(false);
const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface User {
  name: string;
  user_phone: string;
  user_email: string;
  user_id: number;
  user_type: number;
}
const tableData = reactive<User[]>([]);

const search = ref("");
const filterTableData = computed(() =>
  tableData.filter(
    (data) =>
      !search.value || data.name.toLowerCase().includes(search.value.toLowerCase())
  )
);
const handleEdit = (index: number, row: User) => {
  store.setCurrentEditedUser(row);
  console.log(index, row);
  router.push(`/edituser/${row.user_id}`);
};
const inviteUsers = () => {
  router.push("/add_user");
};
const postData = async () => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}getusers?token=${store.getToken}`
    );

    // Handle the API response here
    loading.value = true;

    if (response.status == 200) {
      if (Array.isArray(response.data)) {
        response.data.forEach((element) => {
          let el = element;
          el.user_type = element.user_type == 1 ? "Normal" : "Admin";
          tableData.push(el);
        });
      } else {
        ElMessage({
          message:
            "Your session has expired. Kindy login to continue enjoying our services",
          type: "error",
        });
        logwat();
      }
    } else {
      ElMessage({
        message: "Failed load users. Kindy refresh the app",
        type: "error",
      });
    }
    loading.value = false;
    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    ElMessage({
      message: "Failed load users. Kindy refresh the app",
      type: "error",
    });
    console.error("Error:", error);
  }
};
postData();

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};
const returnUserType = (type: number) => {
  if (type == 1) {
    return "Normal";
  }
  return "Admin";
};
</script>
<style scoped>
.table-search-bar {
  position: fixed;
  margin-top: -5.23rem;
  left: 16rem;
  width: 10rem;
}
</style>
