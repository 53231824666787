<script setup lang="ts">
import { reactive, ref } from "vue";
import { FormInstance, FormRules } from "element-plus";
import { ArrowLeft } from "@element-plus/icons-vue";
import router from "../../router";
import { ElMessage } from "element-plus";
import axios from "axios";

const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  link: "https://something.com/onboard",
});

interface RuleForm {
  link: string;
}

const navigateBack = () => {
  router.push("/add_user");
};
const copyToClipboard = () => {
  return ruleForm.link;
};

const clipboardSuccessHandler = () => {
  ElMessage({
    message: "The link has been copied to your clipboard",
    type: "success",
  });
};

const clipboardErrorHandler = () => {
  ElMessage({
    message: "Failed to copy the link to your clipboard",
    type: "error",
  });
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <el-page-header
          :icon="ArrowLeft"
          title="Back to Invite User"
          @back="navigateBack"
        >
          <template #content>
            <span class="text-large font-200 mr-3"> Create an invite link </span>
          </template>
        </el-page-header>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 20px 0" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"> </el-col>
      <el-col :span="16">
        <div>
          <p class="create-link-label">
            Invite link to join Selus. Note that the link can be used by anyone to join
            your business account
          </p>
        </div>
        <div style="margin: 20px 0" />
      </el-col>
      <el-col :span="4"> </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <div class="grid-content auth-box --el-box-shadow" v-if="!loading">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="0px"
            class="demo-ruleForm invite-many-form-holder"
            :size="default"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="emails">
              <el-input v-model="ruleForm.link" size="large" class="" readonly="" />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                size="large"
                v-clipboard="copyToClipboard()"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
              >
                Copy to clipboard
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-skeleton :rows="5" animated v-if="loading" />
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.ep-form-item__error {
  position: relative;
  margin-top: -10px;
}
.create-link-label {
  color: #555;
  font-size: 14px;
}
</style>
