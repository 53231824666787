<script lang="ts" setup>
import { ElMessage } from "element-plus";
import { MoreFilled } from "@element-plus/icons-vue";
import { ref, onMounted, computed, watch, reactive } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import router from "../../router";
import { useAuthStore } from "../../store/authStore";
import axios from "axios";
import { DateTime } from "luxon";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

declare const google: any;
const loading = ref(true);
// const data = ref(<any>null);
const orderObject = reactive({ data: <any>null });

const myMapRef = ref<any>(null);
const mapCenter = { lat: -1.23266, lng: 36.77494 };

const getOrderDetails = async (order: any) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_ORDERS_SERVICE}orders?order_number=${order}`
    );
    if (response.status == 200) {
      if (response.data.status) {
        orderObject.data = response.data.data;
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
      }
      loading.value = false;
    } else {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
      // Handle the API response here
      loading.value = false;
    }
  } catch (er) {
    console.log(er);
    loading.value = false;
    ElMessage({
      message: "Something went wrong",
      type: "error",
    });
  }
};

const updateBounds = () => {
  const mapInstance = myMapRef.value;

  try {
    if (mapInstance && orderDetailsLoaded) {
      mapInstance.$mapPromise.then((map: any) => {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < orderObject.data.paths.length; i++) {
          bounds.extend(orderObject.data.paths[i]);
        }
        map.fitBounds(bounds);
      });
    } else {
      console.log("failed to update bounds");
    }
  } catch (er) {
    console.log(er);
  }
};

watch(myMapRef, (googleMap) => {
  if (googleMap) {
    googleMap.$mapPromise.then((map: any) => {
      // setTimeout(() => {
      updateBounds();
      // }, 2000);
    });
  }
});

onMounted(() => {});

const orderDetailsLoaded = computed(() => {
  return orderObject.data !== null;
});

const mapPolyline = computed(() => {
  return `[${orderObject.data.order_details.polyline}]`;
});

getOrderDetails(router.currentRoute.value.query.order);

const transformDate = (date: string, index: number) => {
  // if (index === 0) {
  //   return date;
  // }
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm:ss");
};

const extractTextUntilBy = (sentence: string) => {
  // Use a regular expression to match text from the start of the sentence up to 'by'
  const match = sentence.match(/^(.*?)\s+by/i);

  if (match) {
    return match[1].trim();
  }
  return sentence;
};

const mapOptions = {
  styles: [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    { featureType: "poi", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
    { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
    { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    { featureType: "road", elementType: "geometry", stylers: [{ color: "#ffffff" }] },
    { featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    { featureType: "road.local", stylers: [{ visibility: "off" }] },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    { featureType: "water", elementType: "geometry", stylers: [{ color: "#c9c9c9" }] },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ],
};
</script>

<template>
  <el-main v-loading="loading">
    <div style="margin: 0px" />
    <el-row>
      <el-col :span="8">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Track your delivery</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row class="tracking-container" v-if="orderDetailsLoaded">
      <!-- <el-col :span="12" v-if="typeof data.order_details != 'undefined'"> -->
      <el-col :span="12">
        <div class="tracking-details-content">
          <table class="tracking-order-info-table">
            <tr>
              <td>Order No :</td>
              <td>{{ orderObject.data.order_details.order_number }}</td>
            </tr>
            <tr>
              <td>Amount :</td>
              <td>
                KES
                {{ orderObject.data.payment_details.customer_amount.toLocaleString() }}
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>{{ orderObject.data.order_details.order_status_text }}</td>
            </tr>
            <!-- <tr>
              <td>From :</td>
              <td>{{ orderObject.data.order_details.from_name }}</td>
            </tr> -->
            <!-- <tr> -->
            <!-- <td>To :</td> -->
            <!-- <td>{{ orderObject.data.paths[1].name }}</td> -->
            <!-- <td v-if="j !== 0">{{ i.name }}</td> -->

            <!-- <template v-for="(i, j) in orderObject.data.paths">
              </template> -->
            <!-- </tr> -->
            <!-- <template> -->
            <tr v-for="(i, j) in orderObject.data.paths">
              <td>{{ j === 0 ? "From:" : j === 1 ? "To:" : "" }}</td>
              <td>{{ i.name }}</td>
            </tr>
            <!-- </template> -->

            <tr>
              <td>Placed on :</td>
              <td>{{ orderObject.data.order_details.date_created }}</td>
            </tr>
            <tr v-if="orderObject.data.order_details.order_status > 3">
              <td>Delivered on :</td>
              <td>
                {{ transformDate(orderObject.data.delivery_logs.find((log:any) => log.log_type === 'Dropped')['log_time'],1) }}
              </td>
            </tr>
          </table>
          <el-divider />
          <span v-if="orderObject.data.order_details.order_status != 1">
            <table class="tracking-order-info-table">
              <tr>
                <td>Driver name :</td>
                <td>{{ orderObject.data.driver_details.name }}</td>
              </tr>
              <tr>
                <td>Driver Phone:</td>
                <td>{{ orderObject.data.driver_details.phone_number }}</td>
              </tr>
            </table>
            <el-divider />
          </span>
          <div>
            <el-row>
              <el-col :span="12">
                <el-timeline class="tracking-timeline-box">
                  <el-timeline-item
                    v-for="(activity, index) in orderObject.data.delivery_logs"
                    :key="index"
                    type="primary"
                    size="large"
                    :timestamp="transformDate(activity.log_time, index)"
                  >
                    {{ extractTextUntilBy(activity.description) }}
                  </el-timeline-item>
                </el-timeline>
              </el-col>
              <!-- <el-col :span="12" v-if="orderObject.data.order_details.order_status > 3"> -->
              <el-col :span="12" v-show="false">
                <div class="delivery-docs-container">
                  <div class="delivery-docs-header">Delivery Documents</div>
                  <ul class="delivery-docs-list">
                    <li>Document 1</li>
                    <li>Document 1</li>
                    <li>Document 1</li>
                    <li>Document 1</li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="tracking-map-here">
          <GMapMap
            :center="mapCenter"
            :zoom="10"
            map-type-id="terrain"
            :options="mapOptions"
            style="width: 620px; height: 600px"
            ref="myMapRef"
          >
            <GMapMarker
              :key="index"
              v-for="(m, index) in orderObject.data.paths"
              :position="m"
              :clickable="false"
              :draggable="false"
            />
            <GMapPolyline :path="orderObject.data.paths" ref="polyline" />
            <!-- <GMapPolyline :path="mapPolyline" ref="polyline" /> -->
          </GMapMap>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.tracking-container {
  border: 1px solid var(--el-border-color);
  /* padding: 20px; */
  border-radius: 5px;
  margin-bottom: 40px;
}

.tracking-map-here {
  height: 600px;
  width: 600px;
  background-color: #80808052;
}
.tracking-order-info-table td {
  text-align: left;
  font-size: 14px;
  height: 25px;
}
.tracking-order-info-table td:first-child {
  text-align: right;
  font-weight: bolder;
}
.tracking-details-content {
  padding: 10px 40px 0px 20px;
}

.delivery-docs-header {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}
.tracking-timeline-box {
  padding-top: 10px;
  border-right: 1px solid #dddfe6;
  margin-left: -34px;
  padding-right: 25px;
}

.delivery-docs-container {
  padding-left: 24px;
  margin-top: 13px;
  line-height: 150%;
}
.delivery-docs-list {
  font-size: 13px;
  text-align: left;
}
.delivery-docs-list li:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--ep-color-primary);
}
</style>
