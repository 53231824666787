<template>
  <el-main>
    <div class="new-request-panel">
      <div class="new-request-panel-content">
        <div class="new-request-head">
          <el-scrollbar max-height="220px">
            <template v-for="(location, index) in formInputs.locations" :key="index">
              <el-row
                class="new-request-location-row-breather__minimal"
                v-if="index == 0"
              ></el-row>
              <el-row>
                <el-col :span="2"
                  ><el-icon
                    size="small"
                    class="new-request-location-icon"
                    style="display: none"
                    :class="{
                      'new-request-pickup-location-icon': isNewLocationPickupIcon(index),
                      'new-request-delivery-location-icon': isNewLocationDeliveryIcon(
                        index
                      ),
                    }"
                    ><MapLocation /></el-icon
                ></el-col>
                <el-col :span="20"
                  ><GMapAutocomplete
                    :placeholder="setPlaceHolderName(index)"
                    @place_changed="setPlace($event, index)"
                    class="request-stop"
                    :options="autocompleteOptions"
                    :value="location.name"
                  >
                  </GMapAutocomplete
                ></el-col>
                <el-col :span="2" v-show="displayDeleteIcon(index)">
                  <el-tooltip content="Delete this location" placement="top">
                    <el-icon
                      size="small"
                      @click="removeLocation(index)"
                      class="new-request-location-icon new-request-location-icon__actionable"
                      ><Delete /></el-icon
                  ></el-tooltip>
                </el-col>
              </el-row>

              <el-row class="new-request-location-row-breather"></el-row>
            </template>

            <div v-if="allowNewLocation">
              <el-row>
                <el-col :span="2"
                  ><el-icon size="small" color="#008000" class="new-request-location-icon"
                    ><AddLocation /></el-icon
                ></el-col>
                <el-col :span="20">
                  <span
                    class="new-request-add-destination-button"
                    @click="addNewDestination"
                  >
                    <a class="add-destination">Add Destination</a>
                  </span>
                </el-col>
                <el-col :span="2"></el-col>
              </el-row>
            </div>
          </el-scrollbar>
        </div>
        <el-divider />

        <div class="new-request-body">
          <div v-if="showMinimisedBody">
            <el-row v-if="isValidForm">
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="The bubble indicates the total number of locations"
                  placement="top"
                  v-if="formInputs.locations.length > 5"
                >
                  <el-badge
                    :value="formInputs.locations.length"
                    class="item"
                    type="success"
                  >
                    <el-button
                      type="primary"
                      @click="getPriceQuote"
                      class="new-request-full-button"
                      size="large"
                      v-loading="loading"
                    >
                      Get Price Quotes
                    </el-button>
                  </el-badge>
                </el-tooltip>
                <el-button
                  type="primary"
                  @click="getPriceQuote"
                  class="new-request-full-button"
                  size="large"
                  v-loading="loading"
                  v-else
                >
                  Get Price Quotes
                </el-button>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
            <el-row v-else>
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <el-button
                  type="primary"
                  @click=""
                  class="new-request-full-button"
                  size="large"
                  disabled
                >
                  Get Price Quotes
                </el-button>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <p class="cargo-size-heading"></p>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <div class="new-request-vendors-panel">
                  <el-tabs
                    v-model="activeCargoSizeHeaderName"
                    class="demo-tabs"
                    @tab-click="handleCargoSizeTab"
                    :stretch="tabsStretchSettings"
                  >
                    <el-tab-pane label="Small" name="first" v-if="hasSmallVendors">
                      <div
                        v-for="item in smallVendors"
                        class="request-vendor-row"
                        :class="{
                          'request-vendor-row__active': isActiveRow(item.vehicle_type_id),
                        }"
                        @click="setActiveVendor(item.vehicle_name, item.vehicle_type_id)"
                      >
                        <el-row>
                          <el-col :span="8"
                            ><el-icon size="large" class="request-vendor-row-icon"
                              ><Bicycle /></el-icon
                          ></el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-label">
                              <p>{{ item.vehicle_name }}</p></span
                            >
                          </el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-amount">
                              <p>KES {{ item.cost.toLocaleString() }}</p>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Medium" name="second" v-if="hasMediumVendors">
                      <div
                        class="request-vendor-row"
                        :class="{
                          'request-vendor-row__active': isActiveRow(item.vehicle_type_id),
                        }"
                        @click="setActiveVendor(item.vehicle_name, item.vehicle_type_id)"
                        v-for="item in mediumVendors"
                      >
                        <el-row>
                          <el-col :span="8"
                            ><el-icon size="large" class="request-vendor-row-icon"
                              ><Van /></el-icon
                          ></el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-label">
                              <p>{{ item.vehicle_name }}</p></span
                            >
                          </el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-amount">
                              <p>KES {{ item.cost.toLocaleString() }}</p>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="Large" name="third" v-if="hasLargeVendors">
                      <div
                        class="request-vendor-row"
                        :class="{
                          'request-vendor-row__active': isActiveRow(item.vehicle_type_id),
                        }"
                        @click="setActiveVendor(item.vehicle_name, item.vehicle_type_id)"
                        v-for="item in largeVendors"
                      >
                        <el-row>
                          <el-col :span="8"
                            ><el-icon size="large" class="request-vendor-row-icon"
                              ><Van /></el-icon
                          ></el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-label">
                              <p>{{ item.vehicle_name }}</p></span
                            >
                          </el-col>
                          <el-col :span="8">
                            <span class="request-vendor-row-amount">
                              <p>KES {{ item.cost.toLocaleString() }}</p>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <el-tabs
                  v-model="activeRequestActionHeaderName"
                  class="demo-tabs"
                  @tab-click="handleRequestActionTab"
                  :stretch="tabsStretchSettings"
                >
                  <el-tab-pane label="Notes" name="firstAction">
                    <div>
                      <el-input
                        v-model="formNotes"
                        type="textarea"
                        size="large"
                        placeholder="Type your notes here"
                        class="request-form-notes"
                      />
                    </div>
                    <div style="margin: 5px 0" />
                  </el-tab-pane>
                  <el-tab-pane label="Manifest" name="thirdAction">
                    <div>
                      <el-input
                        v-model="formManifestNumber"
                        type="input"
                        size="large"
                        placeholder="Record the manifest number"
                        class="request-form-notes"
                      />
                    </div>
                  </el-tab-pane>

                  <el-tab-pane label="Schedule" name="secondAction">
                    <div class="block">
                      <span class="request-form-schedule-label"
                        >Select the date and time of pick-up</span
                      >
                      <div style="margin: 10px 0" />

                      <el-date-picker
                        v-model="formScheduledDate"
                        type="datetime"
                        placeholder="Select date and time"
                        :default-time="defaultTime"
                        size="large"
                        class="request-form-schedule-date"
                        :disabled-date="disableScheduleDate"
                      />
                      <div style="margin: 5px 0" />
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
            <el-row>
              <el-col :span="2"></el-col>
              <el-col :span="20">
                <div style="margin: 10px 0" />
                <div>
                  <el-button
                    type="primary"
                    @click="placeOrder"
                    class="new-request-full-button"
                    size="large"
                    :disabled="!formActiveVendor"
                    v-loading="order_loading"
                  >
                    Place {{ formActiveVendor }} Order
                  </el-button>
                </div>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <GMapMap
      :center="mapCenter"
      :zoom="14"
      map-type-id="roadmap"
      :options="mapOptions"
      style="width: 100vw; height: 900px"
      ref="myMapRef"
    >
      <GMapMarker
        :key="index"
        v-for="(m, index) in formMarkers"
        :position="m.position"
        :clickable="false"
        :draggable="false"
      />
    </GMapMap>
  </el-main>
</template>

<script lang="ts" setup>
import { computed, ref, reactive, watch, nextTick } from "vue";
import router from "../../router";
import {
  MapLocation,
  AddLocation,
  Van,
  Bicycle,
  Postcard,
  Delete,
  Edit,
} from "@element-plus/icons-vue";
import type { TabsPaneContext } from "element-plus";
import { ElMessage } from "element-plus";
declare const google: any;
import axios from "axios";
import { DateTime } from "luxon";
import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const defaultTime = new Date(2000, 1, 1, 12, 0, 0);

const tabsStretchSettings = ref(true);
const isValidForm = ref(false);
const loading = ref(false);
const order_loading = ref(false);
const showMinimisedBody = ref(true);

const maxDeliveryLocations = ref(16);
const activeRowIndex = ref(1000);
const formActiveVendor = ref("");
const formNotes = ref("");
const formManifestNumber = ref("");
const formScheduledDate = ref("");
const myMapRef = ref<any>(null);
const activeCargoSizeHeaderName = ref("first");
const activeRequestActionHeaderName = ref("firstAction");
const pricingResponse = reactive({ data: <any>null });
const smallVendors = ref([]);
const mediumVendors = ref([]);
const largeVendors = ref([]);

interface googleLocation {
  name: string;
  lat: number;
  lng: number;
  place_id: string;
  key: number;
}

interface formStruct {
  locations: googleLocation[];
}

interface markerStruct {
  position: { lat: number; lng: number };
}
const formMarkers = reactive<markerStruct[]>([]);

const mapCenter = reactive<googleLocation>({
  name: "",
  key: 0,
  lat: -1.26723,
  lng: 36.81026,
  place_id: "",
});

const formInputs = reactive<formStruct>({
  locations: [
    { name: "", key: 0, lat: 0, lng: 0, place_id: "" },
    { name: "", key: 1, lat: 0, lng: 0, place_id: "" },
  ],
});

const removeLocation = (index: number) => {
  formInputs.locations.splice(index, 1);

  removeMarker(index);

  occasionalFormValidityCheck();

  showMinimisedBody.value = true;
};

const removeMarker = (index: number) => {
  formMarkers.splice(index, 1);
};

const isActiveRow = (index: number) => {
  return index == activeRowIndex.value;
};

const setActiveVendor = (name: string, index: number) => {
  activeRowIndex.value = index;
  formActiveVendor.value = name;
};
const setPlace = async (details: any, index: number) => {
  try {
    let location = {
      lat: details.geometry.location.lat(),
      lng: details.geometry.location.lng(),
      key: Date.now(),
      name: details.name,
      place_id: details.place_id,
    };
    formInputs.locations.splice(index, 1, location);
    addMarker(location, index);

    await nextTick();

    occasionalFormValidityCheck();
    showMinimisedBody.value = true;
  } catch (er) {
    ElMessage({
      message: "Failed to add the location, please retry",
      type: "error",
    });
  }
};

const addMarker = (location: any, index: number) => {
  let { lat, lng } = location;
  formMarkers.splice(index, 1, { position: { lat, lng } });
};

const updateBounds = () => {
  const mapInstance = myMapRef.value;
  try {
    if (mapInstance) {
      mapInstance.$mapPromise.then((map: any) => {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < formMarkers.length; i++) {
          bounds.extend(formMarkers[i].position);
        }
        map.fitBounds(bounds);
      });
    } else {
      ElMessage({
        message: "Failed to resize the map",
        type: "error",
      });
    }
  } catch (er) {
    console.log(er);
  }
};

const addNewDestination = () => {
  formInputs.locations.push({
    name: "",
    key: Date.now(),
    lat: 0,
    lng: 0,
    place_id: "",
  });

  occasionalFormValidityCheck();
};

const disableScheduleDate = (date: any) => {
  const today = new Date();
  return date < today;
};

const occasionalFormValidityCheck = () => {
  let emptyLocations = [];
  for (let i = 0; i < formInputs.locations.length; i++) {
    if (formInputs.locations[i].name == "") {
      emptyLocations.push(i);
    }
  }

  if (emptyLocations.length > 0) {
    isValidForm.value = false;
    showMinimisedBody.value = true;
  } else {
    isValidForm.value = true;
  }

  updateBounds();
};

const handleCargoSizeTab = (tab: TabsPaneContext, event: Event) => {};

const handleRequestActionTab = (tab: TabsPaneContext, event: Event) => {};

const autocompleteOptions: any = {
  componentRestrictions: { country: "ke" },
  fields: ["geometry", "name", "place_id"],
};

const isNewLocationPickupIcon = (index: number) => {
  return index == 0;
};

const isNewLocationDeliveryIcon = (index: number) => {
  return index != 0;
};

const setPlaceHolderName = (index: number) => {
  if (index == 0) {
    return "Pick-up address";
  }
  return "Drop-off address";
};

const displayDeleteIcon = (index: number) => {
  if (index < 2) {
    return false;
  }
  return true;
};

const allowNewLocation = computed(() => {
  return formInputs.locations.length < maxDeliveryLocations.value && isValidForm.value;
});

const getXVendors = (size: string) => {
  return pricingResponse.data.quotes.filter((quote: any) => quote.vehicle_size === size);
};

const hasSmallVendors = computed(() => {
  return pricingResponse.data.quotes.some(
    (element: any) => element.vehicle_size == "small"
  );
});

const hasMediumVendors = computed(() => {
  return pricingResponse.data.quotes.some(
    (element: any) => element.vehicle_size == "medium"
  );
});

const hasLargeVendors = computed(() => {
  return pricingResponse.data.quotes.some(
    (element: any) => element.vehicle_size == "large"
  );
});

const getPriceQuote = async () => {
  loading.value = true;

  try {
    const response = await axios.post(
      `${import.meta.env.VITE_PRICING_SERVICE}customerPriceCheck`,
      {
        company_id: store.getUserData.company_id,
        user_id: store.getUserData.user_id,
        locations: formInputs.locations,
      }
    );

    if (response.status == 200) {
      if (response.data.status) {
        showMinimisedBody.value = false;
        pricingResponse.data = response.data.data;
        smallVendors.value = getXVendors("small");
        mediumVendors.value = getXVendors("medium");
        largeVendors.value = getXVendors("large");
        // console.log(response);
        // console.log("pricing done successfully");
      } else {
        ElMessage({
          // message: response.data.message,
          message: "response.data.message 1",
          type: "error",
        });
      }
    } else {
      ElMessage({
        // message: response.data.message,
        message: "response.data.message 2",
        type: "error",
      });
      // Handle the API response here
    }
    loading.value = false;
  } catch (er) {
    console.log(er);
    loading.value = false;
    ElMessage({
      message: "Something went wrong 3",
      type: "error",
    });
  }
};

const placeOrder = async () => {
  order_loading.value = true;
  let scheduled_time = getScheduledTime();

  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ORDERS_SERVICE}createOrder`,
      {
        company_id: store.getUserData.company_id,
        user_id: store.getUserData.user_id,
        pricing_uuid: pricingResponse.data.uuid,
        vehicle_type: activeRowIndex.value,
        payment_plan: 1,
        payment_method: 1,
        source: "web",
        scheduled_time: scheduled_time,
        notes: formNotes.value,
        manifest: formManifestNumber.value,
      }
    );

    if (response.status == 200) {
      if (response.data.status) {
        router.push(`/track?order=${response.data.data.order_number}`);
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
      }
    } else {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
      // Handle the API response here
      order_loading.value = false;
    }
  } catch (er) {
    console.log(er);
    order_loading.value = false;
    ElMessage({
      message: "Something went wrong",
      type: "error",
    });
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};

const mapOptions = {
  styles: [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    { featureType: "poi", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
    { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
    { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    { featureType: "road", elementType: "geometry", stylers: [{ color: "#ffffff" }] },
    { featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    { featureType: "road.local", stylers: [{ visibility: "off" }] },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    { featureType: "water", elementType: "geometry", stylers: [{ color: "#c9c9c9" }] },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ],
};

const getScheduledTime = () => {
  let current_time = DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");

  if (formScheduledDate.value == "") {
    return current_time;
  } else {
    let selected_time = DateTime.fromJSDate(new Date(formScheduledDate.value));
    if (selected_time < current_time) {
      return current_time;
    }
    return selected_time.toFormat("yyyy-MM-dd HH:mm:ss");
  }
};
</script>
<style scoped>
.ep-main {
  padding: 0;
}
.new-request-panel {
  position: absolute;
  margin-top: 30px;

  background-color: #fff;
  width: 400px;
  margin-left: 20px;
  border-radius: 5px;
  z-index: 2;
}
.vue-map-container {
  position: absolute;
  left: 0px;
}
.new-request-panel-content {
  margin: 33px 0;
}
.request-stop {
  width: 100%;
  height: 2.5rem;
  border-radius: 3px;
  outline-style: solid;
  outline-color: #7c7f822e;
  color: var(--ep-input-text-color, var(--ep-text-color-regular));
  padding: 0 8px;
  font-size: small;
}
.request-stop:focus,
.request-stop:focus-visible {
  outline-style: solid;
  outline-color: var(--ep-color-primary);
  border: 0px solid var(--ep-color-primary);
}
.new-request-location-icon {
  height: -webkit-fill-available;
}
.new-request-location-icon__actionable {
  cursor: pointer;
}
.new-request-location-row-breather {
  margin: 8px 0;
}
.new-request-location-row-breather__minimal {
  margin: 1px;
}
.new-request-full-button {
  width: 100%;
}
.cargo-size-heading {
  font-size: 10px;
  color: var(--ep-input-text-color, var(--ep-text-color-regular));
  margin: 0;
  text-align: left;
  text-transform: uppercase;
}
.request-form-notes,
.request-form-schedule-date {
  width: 100%;
}
.request-form-schedule-label {
  font-size: small;
}
.request-vendor-row {
  border-width: 1px;
  border-style: solid;
  border-color: #7c7f822e;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}
.request-vendor-row:hover,
.request-vendor-row__active {
  border-color: var(--ep-input-text-color, var(--ep-text-color-regular));
}
.request-vendor-row div {
  height: 38px;
}
.request-vendor-row-icon {
  display: -webkit-inline-box;
  margin-top: 10px;
}
.request-vendor-row-label,
.request-vendor-row-amount {
  font-size: small;
}
.request-vendor-row-label p,
.request-vendor-row-amount p {
  padding: 0;
}
.new-request-vendors-panel {
  margin-bottom: -10px;
}
.new-request-add-destination-button {
  padding: 0;
  float: left;
  font-size: small;
  cursor: pointer;
}
.new-request-pickup-location-icon {
  color: #001973;
}
.new-request-delivery-location-icon {
  color: #008000;
}
.new-request-scrollable {
  margin-top: 10px;
}
</style>
