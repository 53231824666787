<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import axios from "axios";
import router from "../router";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  password: string;
  repeat_password: string;
}

const validateConfirmPassword = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("Please input the password again"));
  } else if (value !== ruleForm.password) {
    callback(new Error("Please ensure that the password matches"));
  } else {
    callback();
  }
};

const loading = ref(false);
const formSize = ref("default");
const formError = ref("");
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  password: "",
  repeat_password: "",
});

const rules = reactive<FormRules<RuleForm>>({
  password: [
    { required: true, message: "Please enter your new password", trigger: "blur" },
  ],
  repeat_password: [{ validator: validateConfirmPassword, trigger: "blur" }],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      loading.value = true;
      postData();
    } else {
      console.log("error submit!", fields);
    }
  });
};

const postData = async () => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}passreset/change?token=${store.getToken}`,
      {
        password: ruleForm.password,
        token: router.currentRoute.value.query.q,
      }
    );

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        ElMessage({
          message: "Password has been updated successfully",
          type: "success",
        });
        router.push("/login");
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
        if (response.data.message == "Invalid or used token") {
          router.push("/login");
        }
      }
    } else {
      formError.value = "Password reset failed, please retry.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};
</script>
<template>
  <div w="full" class="login-wrapper">
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <div class="auth-title">Create a new password</div>
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="Enter your new password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>

            <el-form-item prop="repeat_password">
              <el-input
                type="password"
                v-model="ruleForm.repeat_password"
                placeholder="Confirm the new password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Create Password
              </el-button>
            </el-form-item>
            <div style="margin: 20px 0" />

            <div>
              <router-link to="/login">Sign in</router-link>
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </div>
</template>
<style scoped>
.auth-box {
  border: 1px solid #7c7f822e;
  border-radius: 3px;
  padding: 3rem;
}
.auth-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}
.login-wrapper {
  margin: 2.5rem 0;
}
.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
</style>
