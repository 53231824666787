// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import ForgotPassword from '../components/ForgotPassword.vue';
import ResetPassword from '../components/ResetPassword.vue';
import Login from '../components/Login.vue';
import Signup from '../components/Signup.vue';
import Users from '../components/business/Users.vue';
import EditUser from '../components/business/EditUser.vue';
import InviteUser from '../components/business/InviteUser.vue';
import InviteMany from '../components/business/InviteMany.vue';
import CreateLink from '../components/business/CreateLink.vue';
import BusinessProfile from '../components/business/BusinessProfile.vue';
import InviteLanding from '../components/business/InviteLanding.vue';
import ViewPayments from '../components/transactions/ViewPayments.vue';
import ViewStatement from '../components/transactions/ViewStatement.vue';
import OrderHistory from '../components/transactions/OrderHistory.vue';
import IndividualProfile from '../components/profile/Profile.vue';
import ChangePassword from '../components/profile/Password.vue';
import NewRequest from '../components/order/NewRequest.vue';
import TrackOrder from '../components/order/TrackOrder.vue';
import RateOrder from '../components/order/RateOrder.vue';


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/business',
    name: 'BusinessProfile',
    component: Users,
  },
  {
    path: '/business',
    component: Users,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/edituser/:id',
    name: 'EditUser',
    component: EditUser,
  },
  {
    path: '/add_user',
    name: 'InviteUser',
    component: InviteUser,
  },
  {
    path: '/bulk_invite',
    name: InviteMany,
    component: InviteMany,
  },
  {
    path: '/create_link',
    name: CreateLink,
    component: CreateLink,
  },
  {
    path: '/business-profile',
    name: 'BusinessProfile',
    component: BusinessProfile,
  },
  {
    path: '/profile',
    name: 'IndividualProfile',
    component: IndividualProfile,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/transactions/payments',
    name: 'ViewPayments',
    component: ViewPayments,
  },
  {
    path: '/transactions/orders',
    name: 'OrderHistory',
    component: OrderHistory,
  },
  {
    path: '/transactions/statement',
    name: 'ViewStatement',
    component: ViewStatement,
  },
  {
    path: '/request',
    name: 'NewRequest',
    component: NewRequest,
  },
  {
    path: '/track/:id',
    name: 'TrackOrder',
    component: TrackOrder,
  },
  {
    path: '/track',
    name: 'TrackOrderQuery',
    component: TrackOrder,
  },
  {
    path: '/rate/:id',
    name: 'RateOrder',
    component: RateOrder,
  },
  {
    path: '/rate',
    name: 'RateOrderQuery',
    component: RateOrder,
  },
  {
    path: '/reset/:id',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/reset',
    name: 'ResetPasswordQuery',
    component: ResetPassword,
  },
  {
    path: '/onboard/:id',
    name: 'InviteLanding',
    component: InviteLanding,
  },
  {
    path: '/onboard',
    name: 'InviteLandingQuery',
    component: InviteLanding,
  },
  { path: '/', redirect: '/request' }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('_panda');
  // const publicPages = ['/signup','/login','/forgot','reset/:id','/onboard/:id'];
  const publicPages = ['Signup','Login','ForgotPassword','ResetPassword','ResetPasswordQuery','InviteLanding'];
  
  const authRequired = !publicPages.includes(to.name);
  const isAuthenticated = token;

  if(authRequired && !isAuthenticated){
    next('/login');
  } 
  else{
    next();
  }

  const queryString = to.fullPath.split("?")[1];

  // Modify the "to" route's path by appending the query string
  if (queryString) {
    to.path += `?${queryString}`;
  }

});

export default router;
