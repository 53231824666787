<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessage } from "element-plus";
import axios from "axios";
import router from "../router";
import { useAuthStore } from "../store/authStore";

interface RuleForm {
  email: string;
  password: string;
}

const loading = ref(false);
const formSize = ref("default");
const formError = ref("");
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  email: "",
  password: "",
});
const store = useAuthStore();

const rules = reactive<FormRules<RuleForm>>({
  email: [
    { required: true, message: "Please enter your email address", trigger: "blur" },
  ],
  password: [
    {
      required: true,
      message: "Please enter your password",
      trigger: "blur",
    },
  ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      loading.value = true;
      postData();
    } else {
      console.log("error submit!", fields);
    }
  });
};

const postData = async () => {
  try {
    const response = await axios.post(`${import.meta.env.VITE_ONBOARDING_SERVICE}login`, {
      email: ruleForm.email,
      password: ruleForm.password,
    });

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        registerSession(response.data);
        if (response.data.data.user_phone == "") {
          ElMessage({
            message: "Welcome aboad! Please take a minute to complete your profile",
            type: "success",
          });
          router.push("/profile");
        } else {
          router.push("/request");
        }
      } else {
        formError.value =
          "Login failed, please enter the correct user name and password.";
      }
    } else {
      formError.value = "Login failed, please enter the correct user name and password.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};
const registerSession = (data: any) => {
  let jwt = data.jsonwebtoken;
  localStorage.setItem("_panda", jwt);
  store.setAuthStatus(true);
  store.setToken(jwt);
  store.setUserData(data.data);
};
</script>
<template>
  <div w="full" class="login-wrapper">
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <div class="auth-title">Sign in to your account</div>
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                type="email"
                v-model="ruleForm.email"
                placeholder="Enter your email"
                size="large"
              />
            </el-form-item>

            <div style="margin: 20px 0" />
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                placeholder="Enter your password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>

            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Login
              </el-button>
            </el-form-item>
            <div style="margin: 20px 0" />

            <div>
              <router-link to="/forgot">Forgot your password?</router-link>
            </div>
            <div style="margin: 20px 0" />
            <div>
              <router-link to="/signup">Dont have an account? </router-link>
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </div>
</template>
<style scoped>
.auth-box {
  border: 1px solid #7c7f822e;
  border-radius: 3px;
  padding: 3rem;
}
.auth-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}
.login-wrapper {
  margin: 2.5rem 0;
}
.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
</style>
