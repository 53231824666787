<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ArrowLeft } from "@element-plus/icons-vue";
import router from "../../router";
import axios from "axios";

const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  name: string;
  email: string;
  phone_no: string;
  contact_person: string;
}

const formSize = ref("default");
const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  name: "",
  phone_no: "",
  email: "",
  contact_person: "",
});

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: "Please enter the name of the company", trigger: "blur" },
  ],
  phone_no: [
    { required: true, message: "Please enter the company phone number", trigger: "blur" },
  ],
  email: [
    {
      required: true,
      message: "Please enter the company email address",
      trigger: "blur",
    },
  ],
  contact_person: [
    {
      required: true,
      message: "Please enter the name of the contact person",
      trigger: "blur",
    },
  ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      loading.value = true;
      formError.value = "The system offline, please try again.";
    } else {
      console.log("error submit!", fields);
    }
  });
};
const navigateBack = () => {
  router.push("/users");
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>

      <el-col :span="8"
        ><el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Manage Business Profile</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>

    <el-row>
      
      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="Enter the company name"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="contact_person">
              <el-input
                v-model="ruleForm.contact_person"
                placeholder="Enter the name of the contact person"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="Enter the business email"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="phone_no" class="moz-sign-up-item-holder">
              <MazPhoneNumberInput
                v-model="ruleForm.phone_no"
                defaultCountryCode="KE"
                :only-countries="['KE', 'UG', 'TZ', 'NG', 'ZA']"
                @update="results = $event"
                :success="results?.isValid"
                class="holder-maz-input"
              />
            </el-form-item>
            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Update
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}

.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
div.m-phone-number-input.holder-maz-input {
  width: 100%;
}
.m-input-wrapper --default-border maz-rounded {
  border-radius: 5px;
}
</style>
