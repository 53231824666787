<script setup lang="ts">
import { ref } from "vue";

const value = ref();
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Rate your delivery experience</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 20px 0" />
        <el-rate
          v-model="value"
          :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
          show-text
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8"> </el-col>
      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow"></div>
        <el-form></el-form>
      </el-col>
      <el-col :span="8"> </el-col>
    </el-row>
  </el-main>
</template>

<style scoped></style>
