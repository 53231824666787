<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessage } from "element-plus";
import { Share, Delete } from "@element-plus/icons-vue";
import router from "../../router";
import axios from "axios";

import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface InviteItem {
  key: number;
  name: string;
  email: string;
}

interface invite {
  invites: InviteItem[];
}

const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleForm = reactive<invite>({
  invites: [
    {
      key: 0,
      name: "",
      email: "",
    },
  ],
});
const ruleFormRef = ref<FormInstance>();

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      loading.value = true;
      postData();
    } else {
      formError.value = "Something went wrong please try again.";
      console.log(ruleForm);
    }
  });
};

const navigateBack = () => {
  router.push("/orders");
};
const removeInvite = (item: InviteItem) => {
  const index = ruleForm.invites.indexOf(item);
  if (index !== -1) {
    ruleForm.invites.splice(index, 1);
  }
};

const addInvite = () => {
  ruleForm.invites.push({
    key: Date.now(),
    name: "",
    email: "",
  });
};

const postData = async () => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}adduser?token=${store.getToken}`,
      {
        email: ruleForm.invites[0].email,
        name: ruleForm.invites[0].name,
        company_id: store.getUserData.company_id,
      }
    );

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        ElMessage({
          message: "The user has been invited",
          type: "success",
        });
        router.push("/users");
      } else {
        ElMessage({
          message:
            "Your session has expired. Kindy login to continue enjoying our services",
          type: "error",
        });
        logwat();
      }
    } else {
      formError.value = "User invitation failed, please retry.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>

      <el-col :span="24">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Invite a User</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>

    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 40px 0" />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="5"> </el-col>
      <el-col :span="14">
        <el-form-item v-if="formError">
          <div class="ep-form-item__error login-wrapper__error">
            {{ formError }}
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="5"> </el-col>
    </el-row>

    <el-row>

      <el-col :span="24">
        <div class="grid-content auth-box --el-box-shadow"></div>
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          label-width="0px"
          class="demo-ruleForm rule-form-inline"
          :size="default"
          status-icon
          v-loading="loading"
          :inline="true"
        >
          <template v-for="(invite, index) in ruleForm.invites" :key="invite.key">
            <el-form-item>
              <el-input
                v-model="invite.name"
                placeholder="Enter name (Optional)"
                size="large"
              />
            </el-form-item>

            <el-form-item
              :prop="'invites.' + index + '.email'"
              :rules="[
                {
                  required: true,
                  message: 'Please input email address',
                  trigger: 'blur',
                },
                {
                  type: 'email',
                  message: 'Please input correct email address',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                v-model="invite.email"
                placeholder="Enter your email"
                size="large"
              />
            </el-form-item>

            <el-form-item
              class="invite-user-delete-icon"
              @click="removeInvite(invite)"
              v-show="false"
            >
              <el-tooltip content="Delete this row" placement="top" v-if="index != 0">
                <el-icon :size="20" color="#33333380"> <Delete /> </el-icon
              ></el-tooltip>
            </el-form-item>
          </template>

          <el-form-item class="invite-user-inline-action" v-show="false">
            <a @click="addInvite"> Add another row </a> &nbsp;or&nbsp;
            <router-link to="/bulk_invite"> Bulk add email addresses </router-link>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm(ruleFormRef)"
              class="invite-single-user-button"
              size="large"
            >
              Send Invites
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>

    </el-row>
    <!-- <div style="margin: 20px 0" /> -->
    <el-row v-show="false">

      <el-col :span="12">
        <div class="create-invite-link-holder">
          <!-- <el-icon :size="20" color="#33333380"> <Share /> </el-icon> -->
          <router-link to="/create_link" class="create-invite-link">
            Create an invite link
          </router-link>
        </div>
      </el-col>
      <el-col :span="6"></el-col>
      <el-col :span="6"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.invite-user-delete-icon:hover {
  cursor: pointer;
}
.invite-user-delete-icon {
  position: absolute;
  margin-left: -20px;
  margin-top: 10px;
}
.invite-user-inline-action {
  cursor: pointer;
}
.invite-user-inline-action a,
.create-invite-link {
  text-decoration: none;
}
.invite-single-user-button {
  margin-left: 40px;
}
.login-wrapper__error {
  width: 40rem;
  margin-top: -20px;
  margin-left: 4rem;
}
.create-invite-link {
  font-size: 14px;
}
.create-invite-link-holder {
  float: left;
}
</style>
