import { defineStore } from 'pinia';

interface userDataInterface{
  company_id:number;
  company_name:string;
  email:string;
  user_id:number; 
  user_name:string; 
  user_phone:string;
  user_type:string;
}


export const useAuthStore = defineStore('authStore', {
  state: () => ({ isAuthenticated: false, token:"", 
    user_data:{company_id:0,company_name:"",email:"",user_id:0, user_name:"", user_phone:"",user_type:""}, 
    current_edited_user:{ user_name: "",user_phone: "",user_email: "",user_type: "",user_status: "",}  
  }),
  getters: {
    getAuthStatus: (state) => state.isAuthenticated,
    getToken: (state) => state.token,
    getUserData: (state) => state.user_data,
    getCurrentEditedUser: (state) => state.current_edited_user,
  },
  actions: {
    setAuthStatus(val:boolean) {
      this.isAuthenticated = val;
    },
    setToken(val:string) {
      this.token = val;
    },
    setUserData(val:userDataInterface) {
      this.user_data = val;
    },
    setCurrentEditedUser(val:any) {
      this.current_edited_user = val;
    },
  },
})