<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ArrowLeft } from "@element-plus/icons-vue";
import router from "../../router";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  name: string;
  email: string;
  phone_no: string;
}

const formSize = ref("default");
const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  name: "",
  phone_no: "",
  email: "",
});

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: "Please enter the name of the company", trigger: "blur" },
  ],
  phone_no: [
    { required: true, message: "Please enter the company phone number", trigger: "blur" },
  ],
  email: [
    {
      required: true,
      message: "Please enter the company email address",
      trigger: "blur",
    },
  ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      loading.value = true;
      postData();
    } else {
      console.log("error submit!", fields);
    }
  });
};

const postData = async () => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}updateuser?token=${store.getToken}`,
      {
        email: ruleForm.email,
        name: ruleForm.name,
        phone: ruleForm.phone_no,
        company_id: store.getUserData.company_id,
        user_id: store.getUserData.user_id,
        user_type: store.getUserData.user_type,
      }
    );

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        ElMessage({
          message: "The user record has been saved",
          type: "success",
        });
        updateStore(ruleForm.name, ruleForm.email, ruleForm.phone_no);
        router.push("/users");
      } else {
        ElMessage({
          message:
            "Your session has expired. Kindy login to continue enjoying our services",
          type: "error",
        });
        logwat();
      }
    } else {
      formError.value = "User update failed, please retry.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};

const updateStore = (name: string, email: string, phone: string) => {
  let state = {
    company_id: store.getUserData.company_id,
    company_name: store.getUserData.company_name,
    user_id: store.getUserData.user_id,
    user_type: store.getUserData.user_type,
    email: email,
    user_name: name,
    user_phone: phone,
  };
  store.setUserData(state);
};

const initializeForm = () => {
  ruleForm.email = store.getUserData.user_email;
  ruleForm.phone_no = store.getUserData.user_phone;
  ruleForm.name = store.getUserData.user_name;
};
initializeForm();

const navigateBack = () => {
  router.push("/request");
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>

      <el-col :span="16">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Manage Profile</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <div style="margin: 0px 0" />
    <el-row>

      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="Enter your name"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="Enter your email"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="phone_no" class="moz-sign-up-item-holder">
              <MazPhoneNumberInput
                v-model="ruleForm.phone_no"
                defaultCountryCode="KE"
                :only-countries="['KE', 'UG', 'TZ', 'NG', 'ZA']"
                @update="results = $event"
                :success="results?.isValid"
                class="holder-maz-input"
              />
            </el-form-item>
            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Update
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}

.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
div.m-phone-number-input.holder-maz-input {
  width: 100%;
}
.m-input-wrapper --default-border maz-rounded {
  border-radius: 5px;
}
</style>
