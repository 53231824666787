<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import router from "../../router";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  old_password: string;
  new_password: string;
  repeat_password: string;
}

const validateConfirmPassword = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("Please input the password again"));
  } else if (value !== ruleForm.new_password) {
    callback(new Error("Please ensure that the password matches"));
  } else {
    callback();
  }
};

const loading = ref(false);
const formError = ref("");
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  old_password: "",
  new_password: "",
  repeat_password: "",
});

const rules = reactive<FormRules<RuleForm>>({
  old_password: [
    { required: true, message: "Please enter your current password", trigger: "blur" },
  ],
  new_password: [
    { required: true, message: "Please enter your new password", trigger: "blur" },
  ],
  repeat_password: [{ validator: validateConfirmPassword, trigger: "blur" }],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      loading.value = true;
      postData();
    } else {
      console.log("error submit!", fields);
    }
  });
};
const postData = async () => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}changepassword?token=${store.getToken}`,
      {
        old_password: ruleForm.old_password,
        new_password: ruleForm.new_password,
        company_id: store.getUserData.company_id,
        user_id: store.getUserData.user_id,
      }
    );

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        ElMessage({
          message: "Password has been updated successfully",
          type: "success",
        });
        router.push("/request");
      } else {
        ElMessage({
          message: response.data.message,
          type: "error",
        });
        logwat();
      }
    } else {
      formError.value = "Password update failed, please retry.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
       
      <el-col :span="8">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/request' }">Homepage</el-breadcrumb-item>
          <el-breadcrumb-item>Manage Password</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 0px 0" />
      </el-col>
    </el-row>
    <el-row>

      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            size="default"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="old_password">
              <el-input
                type="password"
                v-model="ruleForm.old_password"
                placeholder="Enter your old password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>

            <el-form-item prop="new_password">
              <el-input
                type="password"
                v-model="ruleForm.new_password"
                placeholder="Enter your new password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>

            <el-form-item prop="repeat_password">
              <el-input
                type="password"
                v-model="ruleForm.repeat_password"
                placeholder="Confirm the new password"
                size="large"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <div style="margin: 20px 0" />

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Change Password
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </el-main>
</template>
<style scoped>
.auth-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}
.login-wrapper {
  margin: 2.5rem 0;
}
.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
</style>
