<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ArrowLeft } from "@element-plus/icons-vue";
import router from "../../router";
import axios from "axios";

const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  emails: string;
}

const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  emails: "",
});

const checkEmailInput = (input: string) => {
  const emails = input.split(",");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Iterate over each email in the array and check if it's valid
  for (const email of emails) {
    if (!emailRegex.test(email.trim())) {
      return false; // Invalid email found
    }
  }
  return true; // All em
};

const validateEmails = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error("Please enter email addresses separated by a comma"));
  } else if (checkEmailInput(value) !== true) {
    callback(
      new Error("Please ensure that the emails are valid and are separated by a comma")
    );
  } else {
    callback();
  }
};

const rules = reactive<FormRules<RuleForm>>({
  emails: [{ validator: validateEmails, trigger: "blur" }],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      //   loading.value = true;
      formError.value = "The system offline, please try again.";
    } else {
      console.log("error submit!", fields);
      console.log(ruleForm);
    }
  });
};
const navigateBack = () => {
  router.push("/add_user");
};
</script>
<template>
  <el-main>
    <div style="margin: 20px 0" />
    <el-row>
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <el-page-header
          :icon="ArrowLeft"
          title="Back to Invite User"
          @back="navigateBack"
        >
          <template #content>
            <span class="text-large font-200 mr-3"> Bulk User Invite </span>
          </template>
        </el-page-header>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="margin: 20px 0" />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <div class="grid-content auth-box --el-box-shadow">
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm invite-many-form-holder"
            :size="default"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="emails">
              <el-input
                v-model="ruleForm.emails"
                type="textarea"
                size="large"
                placeholder="Enter email addresses separated by a comma"
                class="invite-many-textarea"
              />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm(ruleFormRef)" size="large">
                Submit Invites
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.ep-form-item__error {
  position: relative;
  margin-top: -10px;
}
</style>
