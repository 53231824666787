<script setup lang="ts">
import { reactive, ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { ArrowLeft } from "@element-plus/icons-vue";
import router from "../../router";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useAuthStore } from "../../store/authStore";

const store = useAuthStore();
const token = localStorage.getItem("_panda");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

interface RuleForm {
  name: string;
  email: string;
  phone: string;
  user_type: string;
  // user_status: string;
}

const formSize = ref("default");
const formError = ref("");
const results = ref();
const loading = ref(false);
const ruleFormRef = ref<FormInstance>();
const ruleForm = reactive<RuleForm>({
  name: "",
  phone: "",
  email: "",
  user_type: "",
  // user_status: "",
});

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: "Please enter the name of the user", trigger: "blur" },
  ],
  phone: [
    { required: true, message: "Please enter the user phone number", trigger: "blur" },
  ],
  email: [
    { required: true, message: "Please enter the user email address", trigger: "blur" },
  ],
  user_type: [{ required: true, message: "Please enter the user type", trigger: "blur" }],
  // user_status: [
  //   {
  //     required: true,
  //     message: "Please select the user status",
  //     trigger: "blur",
  //   },
  // ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      loading.value = true;
      postData();
    } else {
      formError.value = "Something went wrong please try again.";
      console.log("error submit!", fields);
      console.log(ruleForm);
    }
  });
};

const postData = async () => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_ONBOARDING_SERVICE}updateuser?token=${store.getToken}`,
      {
        email: ruleForm.email,
        name: ruleForm.name,
        phone: ruleForm.phone,
        user_type: ruleForm.user_type == "Admin" ? 2 : 1,
      }
    );

    // Handle the API response here
    loading.value = false;

    if (response.status == 200) {
      if (response.data.status) {
        ElMessage({
          message: "The user record has been saved",
          type: "success",
        });
        router.push("/users");
      } else {
        ElMessage({
          message:
            "Your session has expired. Kindy login to continue enjoying our services",
          type: "error",
        });
        logwat();
      }
    } else {
      formError.value = "User update failed, please retry.";
    }

    console.log("Response:", response.data);
  } catch (error) {
    // Handle any errors
    loading.value = false;
    formError.value = "Something went wrong please try again.";
    console.error("Error:", error);
  }
};

const logwat = () => {
  store.setAuthStatus(false);
  store.$reset();
  localStorage.removeItem("_panda");
  router.push("/login");
};

const navigateBack = () => {
  router.push("/users");
};

const initializeForm = () => {
  ruleForm.email = store.getCurrentEditedUser.user_email;
  ruleForm.phone = store.getCurrentEditedUser.user_phone;
  ruleForm.name = store.getCurrentEditedUser.user_name;
  ruleForm.user_type = store.getCurrentEditedUser.user_type;
};
initializeForm();
</script>
<template>
  <el-main>
    <div style="margin: 50px 0" />
    <el-row>

      <el-col :span="8">
        <el-page-header :icon="ArrowLeft" title="Back to Users" @back="navigateBack">
          <template #content>
            <span class="text-large font-200 mr-3"> Edit a user </span>
          </template>
        </el-page-header>
      </el-col>
      <el-col :span="8"></el-col>
      <el-col :span="8"></el-col>
    </el-row>
      <div style="margin: 10px 0" />

    <el-row>

      <el-col :span="8">
        <div class="grid-content auth-box --el-box-shadow">
          <p></p>
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-loading="loading"
          >
            <el-form-item v-if="formError">
              <div class="ep-form-item__error login-wrapper__error">
                {{ formError }}
              </div>
            </el-form-item>

            <el-form-item prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="Enter the name of the user"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="Enter the user email"
                size="large"
              />
            </el-form-item>

            <el-form-item prop="phone_no">
              <el-tooltip
                content="Only the users can change their phone numbers"
                placement="top"
              >
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="Enter the user phone number"
                  size="large"
                  disabled
                />
              </el-tooltip>
            </el-form-item>

            <el-form-item prop="user_type">
              <el-select
                v-model="ruleForm.user_type"
                placeholder="please select the user type"
                size="large"
              >
                <el-option label="Admin" value="2" />
                <el-option label="Normal" value="1" />
              </el-select>
            </el-form-item>

            <!-- <el-form-item prop="user_status">
              <el-switch
                v-model="ruleForm.user_status"
                size="large"
                active-text="Active"
                inactive-text="Deactivated"
                class="edit-user-switch"
              />
            </el-form-item> -->

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm(ruleFormRef)"
                class="auth-sign-in-button"
                size="large"
              >
                Update
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="16"></el-col>
    </el-row>
  </el-main>
</template>

<style scoped>
.auth-sign-in-button {
  width: 100%;
  margin: 0px;
}

.login-wrapper__error {
  position: initial;
  margin: 0 auto;
}
.ep-switch__label {
  color: #30313357;
}
</style>
